.layout-one {
    .project-comp {
        text-align: left;
        color: black;

        .header-part {
            padding-bottom: 3vh;
            padding-top: 12vh;
            text-align: center;

        }

        // input:disabled {
        //     background-color: #f0f0f0;
        //     cursor: not-allowed;
        // }

        .quote-list {
            width: 100%;
            height: 60vh;
            white-space: nowrap;
            overflow-x: scroll;
            /* Enables horizontal scroll */
        }

        .quote-list::-webkit-scrollbar {
            width: 11px;
            height: 11px;
            /* Adjust the height of the scrollbar */
        }


        .quote-list::-webkit-scrollbar-thumb {
            background-color: #26455d;
            border-radius: 10px;
        }

        .quote-list::-webkit-scrollbar-track {
            background-color: #f1f1f1;
        }

        // .squote-list::after {
        //     display: inline-block;     
        //     width: 1500px;             /* Extend the width artificially */
        // }
        .pricing-part {
            padding-bottom: 30px;

        }

        .account-settings {
            border-bottom: 2px solid gray;
            border-top: 2px solid #ff9f69 !important;
            padding-top: 30px;
            padding-bottom: 8px;
        }

        .account-setting-selected {
            text-decoration: underline;
        }

        .status-select {
            background-color: #6c7b8b;
            border-radius: 4px;
            padding: 2px 20px;
            border: none;
            color: white;
        }

        .employee-select {
            background-color: #6c7b8b;
            border-radius: 4px;
            padding: 2px 18px;
            border: none;
            color: white;
            min-width: 100px;
        }

        .employee-select:disabled {
            background-color: #bbbabb;
            padding: 2px 25px;
            border-radius: 10px;
            text-shadow: 0 0 0 #bbbabb;
            color: #bbbabb;
        }

        .select-readonly {
            pointer-events: none;
            background-color: #6c7b8b;
            padding: 2px 25px;
            border-radius: 10px;
            border: none;
            appearance: none;
            cursor: default;
            color: white;
        }

        .quote-list table {
            // width: 100vw;
            border-collapse: collapse;

            thead tr th {
                cursor: pointer;
                min-width: 100px;
            }
        }

        .table-row img {
            width: 20px;
            /* Adjust size as needed */
        }


        .loading-screen {
            position: absolute;
            width: 100%;
            height: 100%;
            /* left: 0; */
            /* width: 100vw; */
            /* height: 100vh; */
            background-color: rgba(0, 0, 0, 0);
            z-index: 9999;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            img {
                width: 36px;
            }
        }

        @media screen and (max-width: 100vw) {
            .quote-list table {
                min-width: 1380px;
                /* Ensure the table doesn't shrink too much */
            }
        }

        @media screen and (max-width: 1112px) {
            .header-search {
                order: 2;
            }

            .header-allshow-checkbox {
                order: 1;
            }

            .customer-header-style {
                display: block !important;
            }

            .header-search-part {
                padding-right: 0px !important;
                justify-content: space-between;
            }

            .quote-search-btn {
                padding-right: 7px !important;
            }

            #header-table-searchInput {
                min-width: 25vw;
            }
        }

        .user-table table {
            width: 100%;
            border-spacing: 0px !important;
        }

        .user-table table thead tr th {
            border: none;
            text-align: left;
            padding: 0 10px !important;
        }

        .user-table table thead tr th span {
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-bottom-color: #26455d;
            font-size: medium;
            color: #26455d;
        }

        .user-table table tr:nth-child(odd) {
            background-color: #f1f1f1 !important;
        }

        .user-table table td {
            color: black;
        }

        .quote-list table {
            border-spacing: 0px !important;
        }

        .quote-list table thead tr th {
            border: none;
            text-align: left;
            padding: 0 10px !important;
        }

        .quote-list table tr:nth-child(even) {
            background-color: white !important;
        }

        .quote-list table thead tr th span {
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-bottom-color: #26455d;
            font-size: medium;
            color: #26455d;
        }

        .details-button img {
            width: 28px;
            height: 20px;
        }

        .quote-search-btn {
            padding-right: 40px;
            background: none;
            border: none;
            cursor: pointer;
            font-size: 16px;
            display: flex;
            align-items: center;
        }

        .search-container input {
            margin-right: 2px;
            padding: 5px;
            transition: opacity 0.3s ease-in-out;
            background-color: #6a7c8b;
            border: none;
            color: white;
            width: 15vw;
        }

        .search-container input::placeholder {
            color: white;
        }

        .hidden-input {
            visibility: hidden;
            opacity: 0;
        }

        .visible-input {
            visibility: visible;
            opacity: 1;
        }

        .title-comp {
            display: flex;
            background-color: #7a8491;
            justify-content: center;
            align-items: center;

            label {
                font-size: 28px;
                font-weight: 400;
                color: white;
                background: #323c49;
                padding: 3px 32px;
                margin: 10px;

            }
        }

        .price-blocks {
            background: white;

            .price-content {
                margin: 0px auto;
                padding: 16px;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;

                .project-list {
                    width: calc(25% - 16px);
                    padding: 6px;
                    min-height: 54vh;

                    >h4 {
                        border-bottom: 2px solid #ef9f69;
                        line-height: 42px;
                        margin-bottom: 2vh;

                    }

                    >ul {
                        list-style: none;
                        padding: 0;

                        >li {
                            font-size: 18px;
                            cursor: pointer;
                            padding: 6px;
                        }

                        >li.selected {
                            background-color: #e7e6e6;
                        }
                    }

                    .view-quotes {
                        background: #ff9f69;
                        border: none;
                        color: white;
                        padding: 6px 12px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;

                        img {
                            width: 18px;
                            margin-right: 6px;
                        }
                    }

                    .switch-box {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        border-top: 2px solid gray;

                        >h4 {
                            padding: 0;
                            margin: 0;
                        }
                    }
                }

                .project-show {
                    width: calc(75% - 16px);
                    padding: 6px;

                    >h4 {
                        position: relative;

                        background-color: #26455d;
                        color: white;
                        padding: 6px 12px;

                        .btn-collapse {
                            position: absolute;
                            right: 12px;
                            color: white;
                            background: no-repeat;
                            border: none;
                            top: 3px;

                            svg {
                                transition: all 0.2s;
                            }
                        }

                        .btn-collapse.opened {
                            svg {
                                transform: rotate(180deg);
                            }

                        }
                    }

                    .select-period {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        >div {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                        }

                        button {
                            width: 60px;
                            margin-right: 6px;
                            background-color: lightgray;
                            border: none;
                            border-radius: 12px;
                            color: white;
                        }

                        button:last-child {
                            margin-right: 18px;
                        }

                        select {
                            width: 72px;
                            margin-right: 6px;
                            height: 26px;
                        }

                        button.selected {
                            background-color: #ff9f69;
                        }
                    }



                    .content {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        position: relative;

                        >div {
                            h3 {
                                text-align: center;
                                font-size: 16px;
                            }
                        }

                        .picture-part {
                            .link-block {
                                display: block;
                                -webkit-box-shadow: 6px 6px 8px 0px rgb(50 50 50 / 25%);
                                -moz-box-shadow: 6px 6px 8px 0px rgba(50, 50, 50, 0.25);
                                box-shadow: 6px 6px 8px 0px rgb(50 50 50 / 25%);

                                img {
                                    max-width: 312px;
                                }
                            }

                            .project-item-status {
                                font-size: 14px;
                                color: #394b4e;
                            }

                            #btn-publish {
                                cursor: not-allowed;
                                opacity: .65;
                                filter: alpha(opacity=65);
                                -webkit-box-shadow: none;
                                box-shadow: none;
                                padding: 0px 36px;
                            }

                            .btn-gray {
                                background: #8c8c8c;
                                color: #fff;
                            }
                        }

                        .description-part {
                            padding: 18px 3%;

                            code {
                                padding: 8px;
                                background: inherit;
                                border: 1px solid #f2f3f3;
                                display: block;
                                color: #2d6bbb;
                            }



                        }

                        .quote-list {
                            display: none;
                        }
                    }

                    .content.customer-views {
                        >div {
                            width: 100%;

                            // height: 500px;
                            >canvas {
                                width: 100% !important;
                                border-right: lightgray 1px solid;
                            }
                        }
                    }

                    .content.pie-chart {
                        margin-top: 48px;

                        >div {
                            width: 100%;
                        }

                        .pie-show {
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            flex-wrap: wrap;

                            >div {
                                width: 300px;
                            }

                            >ul {
                                list-style: none;
                                margin-top: 24px;

                                li {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;

                                    div {
                                        width: 24px;
                                        height: 24px;
                                    }

                                    label.pie-name {
                                        margin-left: 12px;
                                        overflow: hidden;
                                        font-size: 18px;
                                    }

                                    .percentage {
                                        margin-left: 12px;
                                    }
                                }
                            }

                        }

                    }

                    .content.quote-show {

                        .picture-part {
                            display: none;
                        }

                        .description-part {
                            display: none;
                        }

                        .quote-list {
                            display: block;
                            width: 100%;

                            table {
                                width: 100%;
                                border-spacing: 0 0.5em;
                                border-collapse: separate;

                                tr {
                                    background: #f6f5f5;

                                    td {
                                        color: black;
                                        line-height: 25px;
                                    }

                                }

                                button {
                                    border: none;
                                    background: white;
                                    margin: 6px;
                                    padding: 0 6px;
                                    border-radius: 3px;
                                    text-transform: uppercase;
                                }
                            }

                            .quote-pages {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                margin-top: 12px;

                                .quote-page {
                                    width: 24px;
                                    height: 24px;
                                    margin-left: 8px;
                                    background-color: #26455d;
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: white;
                                }

                                .selected-quote {
                                    background-color: white;
                                    color: #26455d;
                                    border: 1px solid #26455d;
                                }
                            }
                        }


                    }
                }
            }


        }

        .price-contact {
            max-width: 1024px;
            margin: 0 auto;
            color: white;
            text-align: left;

            h2 {
                padding: 12px 0;
            }

            >div {
                text-align: 'center';
                padding-bottom: 42px;
                padding-top: 16px;
                display: flex;
            }

        }

        .price-contact.terms-service {
            p {
                margin: 0;
                font-weight: 200;
                font-size: 13px;
            }

            h4 {
                text-align: center;
            }
        }

        .learn-more {
            display: block;
            font-weight: bold;
            margin: 0px auto;
            border-radius: 24px;
            background: #ef9f69;
            color: white;
            border: 1px solid;
            font-size: 14px;
            padding: 6px 24px;
            cursor: pointer;
            margin-bottom: 42px;
            border: none;
        }

        .pricing-title {
            color: #26455d;
            font-size: 1.5rem;
        }

        .pricing-title-bottom {
            height: 1px;
            background-color: gray;
            width: 100%;
        }

        .pricing-subtitle-container {
            width: 100px;
            margin-top: 16px;
            margin-bottom: 16px;
        }

        .pricing-subtitle {
            color: #26455d;
            font-size: 1.2rem;
            font-weight: bold;
            border-bottom: 1px solid gray;
        }

        .pricing-item-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 12px;
            margin-top: 16px;
            margin-bottom: 16px;
        }

        .pricing-size-item-title {
            color: #26455d;
            font-size: 1.2rem;
            font-weight: bold;
        }

        .pricing-item-price-container {
            display: flex;
            justify-content: center;
            border: 2px solid #26455d;
            padding-left: 16px;
            padding-right: 16px;
            margin-top: 12px;
        }

        .pricing-item-price {
            color: #26455d;
            font-size: 1.1rem;
            width: 120px;
            text-align: center;
            border: 1.4px solid #26455d;
            margin-top: 12px;
        }

        .free-asset-const {
            width: 30px;
            height: 20px;
            font-size: unset;
            text-align: center;
            margin-left: 2px;
        }

        .pricing-footer {
            display: flex;
            justify-content: center;
        }

        .pricing-button {
            border: none;
            border-radius: 4px;
            color: white;
            padding: 6px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 240px;
            margin: 36px 16px;
        }

        .pricing-button.clear {
            background-color: rgb(110, 124, 124);
        }

        .pricing-button.save {
            background-color: #ff9f69;
        }

        .upload-pricing-button {
            border: none;
            border-radius: 4px;
            color: white;
            padding: 6px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 240px;
            background-color: #ff9f69;
            margin-bottom: 5px;
        }

        // .upload-pricing-button.clear {
        //     background-color: rgb(110, 124, 124);
        // }

        // .upload-pricing-button.save {
        //     background-color: #ff9f69;
        // }

        .siding-panel .siding-item .size-image .selected {
            border: 4px solid #EF9F69;
        }

        .siding-panel .siding-item .size-image img {
            height: 72px;
            width: 90px;
            // border-radius: 45px;
            cursor: pointer;
        }

        .select-panel {
            display: flex;
            flex-wrap: wrap;
        }

        .select-panel .style-item {
            display: flex;
            flex-wrap: wrap;

            .pricing-item-container {
                cursor: pointer;
            }
        }

        .select-panel .style-item .size-image.selected {
            border: 2px solid #466F9D;
        }

        .select-panel .style-item .size-image img {
            height: 72px;
            width: 90px;
            // border-radius: 45px;
        }

        .access-item .package-item {
            width: 94px;
            height: 108px;
            background-repeat: no-repeat;
            background-size: 90px;
            cursor: pointer;
        }

        .tooltip-div {
            position: relative;
            display: inline-block;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        /* Tooltip text */
        .tooltip-div .tooltip-content {
            visibility: hidden;
            max-width: 220px;
            background-color: #466F9D;
            color: #fff;
            text-align: center;
            padding: 2px 15px;
            width: max-content;
            border-radius: 3px;

            top: calc(100% - 90px);
            // left: 50%;
            // margin-left: -50%;

            /* Position the tooltip text - see examples below! */
            position: absolute;
            z-index: 1;

            opacity: 0;
            transition: visibility 0s linear 500ms, opacity 300ms;
            cursor: pointer;
        }

        /* Show the tooltip text when you mouse over the tooltip container */
        .tooltip-div:hover .tooltip-content {
            visibility: visible;
            opacity: 1;
            transition: visibility 0s linear 0s, opacity 500ms;

        }

    }

    @media screen and (max-width: 1024px) {
        .project-comp {
            .price-blocks {
                .price-content {
                    padding: 6px;

                    .project-list {
                        width: calc(40% - 6px);

                        >ul {
                            >li {
                                font-size: 14px;
                            }
                        }
                    }

                    .project-show {
                        width: calc(60% - 16px);

                        .content {
                            flex-direction: column;
                        }

                        .content.quote-show {
                            table {
                                tbody {
                                    tr {
                                        td {
                                            // display: none;
                                        }

                                        td:nth-child(1) {
                                            display: table-cell;
                                        }

                                        td:nth-child(2) {
                                            display: table-cell;
                                        }
                                    }
                                }
                            }
                        }


                    }
                }
            }
        }

    }

    @media screen and (max-width: 720px) {
        .project-comp {
            .price-blocks {
                .price-content {
                    flex-direction: column;
                    padding: 1vh 3vw;

                    .project-list {
                        width: 100%;
                    }

                    .project-show {
                        width: 100%;
                    }

                }
            }
        }
    }
}

.modal-quote-details {


    .modal-content {
        border-radius: 18px;
    }

    .modal-header {
        border: none;
        padding: 6px;
        align-items: flex-end;
        justify-content: flex-end;

        .btn-exit {
            padding: 0;
            margin-top: 6px;
            margin-right: 6px;
            width: 16px;
            border: none;
            background: none;
        }
    }

    .modal-footer {
        border: none;
        padding: 0;
        align-items: center;
        justify-content: center;

        button {
            margin-bottom: 18px;
            width: 320px;
            border-radius: 18px;
            background-color: #ef9f69;
            border: none;
        }
    }

    .modal-body {
        .email-success {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            text-align: center;

            img {
                height: 120px;
            }

            h3 {
                font-size: 20px;
                font-weight: 600;
                margin-top: 12px;
            }

            p {

                word-break: break-all;
                margin: 0;
            }

            .content-block {
                text-align: left;
                width: 90%;

                h5 {
                    font-size: 18px;
                    font-weight: 600;
                    margin-top: 18px;
                    color: black;

                }
            }
        }
    }

}

.modal-save-pricing {

    .modal-content {
        border-radius: 18px;
    }

    .modal-header {
        border: none;
        padding: 6px;
        align-items: flex-end;
        justify-content: flex-end;

        .btn-exit {
            padding: 0;
            margin-top: 6px;
            margin-right: 6px;
            width: 16px;
            border: none;
            background: none;
        }
    }

    .modal-footer {
        border: none;
        padding: 0;
        align-items: center;
        justify-content: center;

        button {
            margin-bottom: 18px;
            width: 120px;
            border-radius: 18px;
            background-color: #0d6efd;
            border: none;
        }
    }

    .modal-body {
        .price-success {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            text-align: center;

            img {
                height: 120px;
            }

            h3 {
                font-size: 20px;
                font-weight: 600;
                margin-top: 12px;
            }

            p {

                word-break: break-all;
                margin: 0;
            }

            .content-block {
                text-align: left;
                width: 90%;

                h5 {
                    font-size: 18px;
                    font-weight: 600;
                    margin-top: 18px;
                    color: black;

                }
            }
        }
    }

}

.modal-transaction-details {
    .modal-content {
        border-radius: 18px;
    }

    .modal-header {
        border: none;
        padding: 6px;
        align-items: flex-end;
        justify-content: flex-end;

        .btn-exit {
            padding: 0;
            margin-top: 6px;
            margin-right: 6px;
            width: 16px;
            border: none;
            background: none;
        }
    }

    .modal-footer {
        border: none;
        padding: 0;
        align-items: center;
        justify-content: center;

        button {
            margin-bottom: 18px;
            width: 160px;
            border-radius: 18px;
            background-color: #ef9f69;
            border: none;
            margin-left: 8px;
        }
    }

    .modal-body {
        border: solid 1px;
        margin: 12px;

        .email-success {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            // align-items: center;
            text-align: center;

            img {
                width: 60px;
                height: 60px;
            }

            h2 {
                // font-size: 20px;
                font-weight: 600;
                margin-top: 12px;
                margin-left: 8px;
                color: #26455d;
            }

            p {
                word-break: break-all;
                margin: 0;
            }

            .content-block {
                text-align: left;
                // width: 90%;

                h5 {
                    font-size: 18px;
                    font-weight: 600;
                    margin-top: 18px;
                    // color: black;
                    background-color: gray;
                    color: white;

                }

                .divider {
                    width: 100%;
                    height: 1px;
                    background-color: gray;
                }

                .spacer {
                    width: 100%;
                    height: 120px;
                }
            }
        }
    }
}