.layout-one {
    .contact-us {
        text-align: center;


        .learn-more {
            margin-top: 54px;
            display: block;
        }

        .config-sell {
            display: flex;
            justify-content: center;
            align-items: flex-start;

            .config-sell-left {
                display: inline-block;
                width: 42%;
                padding: 2%;

                .title {
                    font-size: 2vw;
                }
            }

            .config-sell-right {
                display: inline-block;
                width: 46%;
                padding: 2%;

                .show-me-card {
                    background: #eee;
                    padding-bottom: 5%;
                    border-radius: 6px;
                    overflow: hidden;
                    .header {
                        background: #ef9f69;
                        color: white;
                        padding: 4%;
                        h3 {
                            margin: 0;
                            text-align: center;
                            font-weight: 600;
                            font-size: 21px;
                        }
                    }

                    .content {
                        padding: 6%;
                        .item-field {
                            p {
                                margin-bottom: 6px;
                                margin-top: 12px;
                                font-size: 14px;
                                font-weight: 600;
                                span {
                                    color: #ef9f69;
                                }
                            }
                            input {
                                width: 100%;
                                line-height: 21px;
                                border: none;
                                border-radius: 16px;
                                height: 32px;
                                padding: 0 12px;
                            }
                            label {
                                font-size: 12px;
                            }
                            .learn-more {
                                border: none;
                                text-transform: uppercase;
                                padding: 6px 24px;
                            }
                        }

                    }
                }

                .phone-number{
                    text-align: center;
                    margin-top: 1vh;
                    margin-bottom: 0;
                    font-size: 21px;
                }
            }
        }



        .config-sell-left {
            ul {
                list-style: none;
                padding: 0;
                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    >div {
                        font-size: 30px;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        border: 2px solid black;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    >p {
                        width: calc(100% - 100px);
                        font-size: 21px;
                    }
                }
            }
        }
    }

}

.contact-us {

    .modal-content {
        border-radius: 18px;
    }

    .modal-header {
        border: none;
        padding: 6px;
        align-items: flex-end;
        justify-content: flex-end;
        .btn-close {
            padding: 0;
            margin-top: 6px;
            margin-right: 6px;
            height: 3px;
            width: 16px;
            border-width: 1px;
        }
    }

    .modal-footer {
        border: none;
        padding: 0;
        align-items: center;
        justify-content: center;

        button {
            margin-bottom: 18px;
            width: 320px;
            border-radius: 18px;
            background-color: #ef9f69;
            border: none;
        }
    }

    .modal-body {
        .email-success {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            text-align: center;
            img {
                height: 120px;
            }

            h3 {
                font-size: 20px;
                font-weight: 600;
                margin-top: 12px;
            }

            p {
                margin: 0;
            }
        }
    }
}