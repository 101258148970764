.signin-comp {
    background-color: #f4f7f9;

    .title-comp {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 6vh;

        label {
            font-size: 28px;
            font-weight: 400;
            color: white;
            background: #26455d;
            padding: 6px 3%;
            margin: 12px;
            min-width: 48%;

        }
    }

    .gradient-line {
        margin: 0 -16px 8px -16px;
        height: 1px;
        background: -moz-linear-gradient(left, rgba(49, 68, 72, 0) 0%, #314448 30%, #314448 70%, rgba(49, 68, 72, 0) 100%);
        background: -webkit-linear-gradient(left, rgba(49, 68, 72, 0) 0%, #314448 30%, #314448 70%, rgba(49, 68, 72, 0) 100%);
        background: linear-gradient(to right, rgba(49, 68, 72, 0) 0%, #314448 30%, #314448 70%, rgba(49, 68, 72, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1a314448', endColorstr='#1a314448', GradientType=1);

        max-width: 72%;
        margin: 0 auto;
    }

    .price-contact {
        max-width: 1024px;
        margin: 0 auto;
        color: #394b4e;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding-bottom: 24vh;

        h2 {
            padding: 12px 0;
        }

        .create-account {
            a {
                color: #ef9f69;
                text-decoration: underline;
            }
        }

        >h3 {
            font-size: 21px;
            width: 60%;
            font-weight: 600;
        }

        .account-info {
            width: 60%;
            padding: 12px;

            div.error-dialog {
                background-color: #f2dede;
                border: 1px dotted #e3bfbf;
                padding: 12px;
                border-radius: 6px;
                position: relative;

                p {
                    color: #ac4a48;
                    word-break: normal;
                    margin-bottom: 12px;
                    font-size: 14px;
                }

                span {
                    color: #e3bfbf;
                    position: absolute;
                    top: 12px;
                    right: 18px;
                    cursor: pointer;
                }

            }

            input {
                width: 100%;
                padding: 0 6px;
            }

            p {
                margin-bottom: 6px;
                margin-top: 12px;
            }

            div.button-div {
                width: 100%;
                margin-top: 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                button {
                    border: none;
                    color: white;
                    background: #ef9f69;
                    padding: 3px;
                    border-radius: 3px;
                    width: 180px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                p {
                    margin: 0;
                    color: #428bca;
                    cursor: pointer;
                    font-size: 16px;
                }

                p:hover {
                    text-decoration: underline;
                }

                /* Container styles */
                .dots-loader {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 6px;
                    margin-left: 6px;
                    margin-top: 6px;
                    /* Space between dots */
                }

                /* Common styles for dots */
                .dots-loader div {
                    width: 4px;
                    height: 4px;
                    background-color: white;
                    border-radius: 50%;
                    animation: bounce 1s infinite ease-in-out;
                }

                /* Animation delay for each dot */
                .dots-loader div:nth-child(1) {
                    animation-delay: 0s;
                }

                .dots-loader div:nth-child(2) {
                    animation-delay: 0.2s;
                }

                .dots-loader div:nth-child(3) {
                    animation-delay: 0.4s;
                }

                /* Keyframes for the bounce effect */
                @keyframes bounce {

                    0%,
                    80%,
                    100% {
                        transform: scale(0);
                        /* Dot shrinks */
                        opacity: 0.3;
                    }

                    40% {
                        transform: scale(1);
                        /* Dot grows back to normal size */
                        opacity: 1;
                    }
                }

            }
        }

        .status-bar {
            text-align: center;
            padding-bottom: 5vh;
            padding-top: 3vh;
            width: 72%;
        }


        .stages-wrapper {
            display: flex;
            align-items: center;
            margin: 32px 0 32px 0;
        }

        .stages-wrapper>.stage {
            width: 56px;
            height: 56px;
            line-height: 56px;
            position: relative;
            border: 1px solid #cacecf;
            border-radius: 50%;
            background: #d7dadb;
            font-size: 28px;
            text-align: center;
            color: #fff;
        }

        .stages-wrapper>.stage.active {
            background: #ef9f69;
        }

        .stages-wrapper>.stage-path {
            background: #d7dadb;
            flex-grow: 1;
            flex-shrink: 0;
            height: 8px;
            margin: 0 -1px 0 -1px;
            border-top: 1px solid #cacecf;
            border-bottom: 1px solid #cacecf;
            z-index: 1;
        }

        .stages-wrapper>.stage-path {
            background: #d7dadb;
            flex-grow: 1;
            flex-shrink: 0;
            height: 8px;
            margin: 0 -1px 0 -1px;
            border-top: 1px solid #cacecf;
            border-bottom: 1px solid #cacecf;
            z-index: 1;
        }

        .stages-wrapper>.stage>.desc {
            color: #394b4e;
            font-size: 16px;
            line-height: 16px;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate(-50%, 4px);
        }
    }

    .description {
        width: 60%;
        margin-top: 18px;
        background: #26455d;
        color: white;
        padding: 6px 0;
        border-radius: 6px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        label {
            margin: 0;
        }

        .level {
            font-size: 24px;
            font-weight: 600;
        }

        .price {
            font-size: 18px;
        }

        .styles {
            font-size: 16px;
        }
    }

    .modal-content {
        width: 60%;
        margin-top: 2vh;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        border: none;
        border-radius: 12px;

        form {
            border: none;
        }

        .modal-body {
            .form-group {
                display: flex;
                width: 100%;
                justify-content: flex-start;
                align-items: center;

                label {
                    width: 120px;
                }
            }

            .input-group-btn {
                position: relative;
                font-size: 0;
                white-space: nowrap;
            }

            .input-group-addon,
            .input-group-btn {
                white-space: nowrap;
                vertical-align: middle;

                button {
                    font-size: 14px;
                    background-color: #428bca;
                    border-color: #428bca;
                    border-radius: 4px;
                    margin-left: 4px;
                    padding: 3px 10px;
                    margin-top: 6px;
                }
            }
        }

        .modal-footer {
            button {
                padding: 2px 56px;
            }
        }

        .subscribe-content {
            padding: 3vh 12px;
            display: flex;
            justify-content: space-around;
            align-items: center;

            img {
                width: 45%;
            }

            .subscribe {
                h4 {
                    font-weight: 600;
                }

                h5 {
                    font-weight: 600;
                }

                p {
                    font-size: 16px;
                }

                button {
                    border: none;
                    background-color: #ef9f69;
                    color: white;
                    padding: 3px 24px;
                    border-radius: 6px;
                }

                .Create {
                    background-color: #28a745;
                }
            }


        }

        .date-box {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .select-month {
                width: 48px;
            }

            span {
                margin: 0 12px;
            }

            .select-year {
                width: 72px;
            }
        }

        option {
            min-height: 40px;
        }


    }


}