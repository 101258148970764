.payment-comp {
    background-color: #f4f7f9;

    .title-comp {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 3vh;

        label {
            font-size: 28px;
            font-weight: 400;
            color: white;
            background: #0d1e33;
            padding: 6px 3%;
            margin: 12px;
            min-width: 48%;

        }
    }

    .space-div {
        height: 30vh;
    }

    .gradient-line {
        margin: 0 -16px 8px -16px;
        height: 1px;
        background: -moz-linear-gradient(left, rgba(49, 68, 72, 0) 0%, #314448 30%, #314448 70%, rgba(49, 68, 72, 0) 100%);
        background: -webkit-linear-gradient(left, rgba(49, 68, 72, 0) 0%, #314448 30%, #314448 70%, rgba(49, 68, 72, 0) 100%);
        background: linear-gradient(to right, rgba(49, 68, 72, 0) 0%, #314448 30%, #314448 70%, rgba(49, 68, 72, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1a314448', endColorstr='#1a314448', GradientType=1);

        max-width: 72%;
        margin: 0 auto;
    }

    .price-contact {
        max-width: 1024px;
        margin: 0 auto;
        color: #394b4e;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        h2 {
            padding: 12px 0;
        }

        .create-account {
            a {
                color: #ef9f69;
                text-decoration: underline;
            }
        }

        >h3 {
            font-size: 21px;
            width: 60%;
            font-weight: 600;
        }

        .account-info {
            width: 60%;
            padding: 12px;

            input {
                width: 100%;
            }

            p {
                margin-bottom: 6px;
                margin-top: 12px;
            }

            div {
                width: 100%;
                margin-top: 24px;
                display: flex;
                justify-content: flex-end;

                button {
                    border: none;
                    color: white;
                    background: #ef9f69;
                    padding: 3px 12px;
                    border-radius: 3px;
                    line-height: 24px;
                }
            }
        }

        .status-bar {
            text-align: center;
            padding-bottom: 5vh;
            padding-top: 3vh;
            width: 72%;
        }


        .stages-wrapper {
            display: flex;
            align-items: center;
            margin: 32px 0 32px 0;
        }

        .stages-wrapper>.stage {
            width: 56px;
            height: 56px;
            line-height: 56px;
            position: relative;
            border: 1px solid #cacecf;
            border-radius: 50%;
            background: #d7dadb;
            font-size: 28px;
            text-align: center;
            color: #fff;
        }

        .stages-wrapper>.stage.active {
            background: #ef9f69;
        }

        .stages-wrapper>.stage-path {
            background: #d7dadb;
            flex-grow: 1;
            flex-shrink: 0;
            height: 8px;
            margin: 0 -1px 0 -1px;
            border-top: 1px solid #cacecf;
            border-bottom: 1px solid #cacecf;
            z-index: 1;
        }

        .stages-wrapper>.stage-path {
            background: #d7dadb;
            flex-grow: 1;
            flex-shrink: 0;
            height: 8px;
            margin: 0 -1px 0 -1px;
            border-top: 1px solid #cacecf;
            border-bottom: 1px solid #cacecf;
            z-index: 1;
        }

        .stages-wrapper>.stage>.desc {
            color: #394b4e;
            font-size: 16px;
            line-height: 16px;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate(-50%, 4px);
        }
    }

    .description {
        width: 60%;
        margin-top: 18px;
        background: #26455d;
        color: white;
        padding: 6px 0;
        border-radius: 6px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        label {
            margin: 0;
        }

        .level {
            font-size: 24px;
            font-weight: 600;
        }

        .price {
            font-size: 18px;
        }

        .styles {
            font-size: 16px;
        }
    }

    .modal-content {
        width: 60%;
        margin-top: 2vh;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        border: none;
        border-radius: 12px;
        padding: 16px;

        form {
            border: none;
        }

        .modal-body {
            .form-group {
                display: flex;
                width: 100%;
                justify-content: flex-start;
                align-items: center;
                padding-bottom: 16px;

                label {
                    width: 120px;
                }
            }

            .input-group-btn {
                position: relative;
                font-size: 0;
                white-space: nowrap;
            }

            .input-group-addon,
            .input-group-btn {
                white-space: nowrap;
                vertical-align: middle;

                button {
                    font-size: 14px;
                    background-color: #428bca;
                    border-color: #428bca;
                    border-radius: 4px;
                    margin-left: 4px;
                    padding: 3px 10px;
                    margin-top: 6px;
                }
            }
        }

        .modal-footer {
            button {
                padding: 2px 56px;
            }
        }

        .subscribe-content {
            padding: 3vh 12px;
            display: flex;
            justify-content: space-around;
            align-items: center;

            img {
                width: 45%;
            }

            .subscribe {
                h4 {
                    font-weight: 600;
                }

                h5 {
                    font-weight: 600;
                }

                p {
                    font-size: 16px;
                }

                button {
                    border: none;
                    background-color: #ef9f69;
                    color: white;
                    padding: 3px 24px;
                    border-radius: 6px;
                }

                .Create {
                    background-color: #28a745;
                }

              
            }


        }

        .control-coupon {
            padding-right: 130px;
        }

        .date-box {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .select-month {
                width: 70px;
                padding: 4px;
            }

            span {
                margin: 0 12px;
            }

            .select-year {
                width: 80px;
                padding: 4px;
            }
        }

        option {
            min-height: 40px;
        }

        .terms-service {
            >h6 {
                margin-top: 2.4vh;
                font-size: 16px;
                font-weight: 600;
            }

            >.contents {
                color: gray;
                padding: 12px;
                font-size: 14px;
                border: 1px solid lightgray;
            }

            >.check-box {
                margin-top: 1vh;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: gray;

                >label {
                    color: #ef9f69;
                    text-decoration: underline;
                    font-size: 16px;
                }
            }
        }


    }

    .modal-content.payment-complete {
        width: 100%;
        margin-top: 3px;
        border-radius: 0;
        box-shadow: none;

        .payment-header {
            background-color: #eaf4e4;
            padding: 6px 0;

            p {
                text-align: center;
                margin-bottom: 0;
            }
        }

        .payment-content {
            padding: 2vh;

            h1 {
                font-weight: 600;
            }

            .contents-list {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                .contents-item {
                    width: 33%;
                    min-width: 300px;
                }
            }
        }

        .subscription-content {
            padding: 0 2vh;

            >div {
                padding: 3vh 0;
                border-top: 1px solid lightgray;
                border-bottom: 1px solid lightgray;

                table {
                    width: 100%;

                    th {
                        text-align: left;
                        border: none;
                        color: #ef9f69;
                    }

                    th:last-child {
                        width: 10%;
                    }

                    tbody {
                        tr {
                            background: #f4f7f9;

                            td {
                                font-weight: 400;
                                color: #0d1e33;
                            }
                        }
                    }
                }
            }

        }

        p.total-amount {
            padding: 1vh 2vh;
            text-align: right;
            font-size: 18px;
            font-weight: 600;
            margin: 0;
        }

        .payment-continue {
            display: flex;
            width: 100%;
            background: #f4f7f9;
            justify-content: flex-end;
            align-items: flex-start;

            button {
                border: none;
                background-color: #ef9f69;
                color: white;
                padding: 3px 66px;
                border-radius: 6px;
                margin-top: 1vh;
            }
        }



    }


    .modal-content.subscription-card {
        width: 320px;
        margin-top: 3.6vh;
        box-shadow: 0 3px 3px rgb(0 0 0 / 50%);
        border: none;
        border-radius: 3px;
        text-align: center;

        .block-header {
            font-size: 21px;
            padding: 9px;
            background: #0d1e33;
            color: white;
        }

        .block-price {
            p {
                margin: 0;
                line-height: 54px;
                font-size: 21px;
                border-bottom: 1px dotted lightgray;
            }

            p:nth-child(2) {
                font-size: 18px;
            }

        }

        p.block-tab {
            margin: 0;
            padding: 18px 0;
            border-bottom: 1px dotted lightgray;

            button {
                border: none;
                color: white;
                background: #ef9f69;
                padding: 6px 0px;
                border-radius: 3px;
                line-height: 24px;
                width: 216px;
                text-transform: uppercase;
                cursor: pointer;
            }

            button.Create {
                background: #28a745;
            }

            button.Disable { 
                background-color: lightgray;
            }
        }
    }


    div.error-dialog {
        background-color: #f2dede;
        border: 1px dotted #e3bfbf;
        padding: 12px;
        border-radius: 6px;
        position: relative;

        p {
            color: #ac4a48;
            word-break: normal;
            margin-bottom: 6px;
            font-size: 14px;
        }

        span {
            color: #e3bfbf;
            position: absolute;
            top: 12px;
            right: 18px;
            cursor: pointer;
        }

    }

    .subscription-error{
        width: 60%;
        margin-top: 3vh;
    }

    .account-error{
        width: 60%;
    }


}


.cancel-modal{

    .modal-content {
        border-radius: 18px;
    }

    .modal-header {
        border: none;
        padding: 12px;
        .btn-exit {
            padding: 0;
            margin-top: 6px;
            margin-right: 6px;
            height: 3px;
            width: 16px;
            border: none;
            background: none;
        }
    }

    .modal-footer {
        border: none;
        padding: 0;
        align-items: center;
        justify-content: center;

        button {
            margin: 18px 12px;
            width: 120px;
            border-radius: 18px;
            background-color: #ef9f69;
            border: none;
            margin-top: 0;
        }
    }

    .modal-body {
        .email-success {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            text-align: center;
            img {
                height: 120px;
            }

            h3 {
                font-size: 20px;
                font-weight: 600;
                margin-top: 12px;
            }

            p {
                margin: 0;
            }
        }
    }
}