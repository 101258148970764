.affiliate-page-header {
    display: flex;
    // min-height: 100vh;
    width: 100%;
    height: 85px;
    background-color: #0d1e33;
    .sidebar {
      width: 405px;
      // background-color: #2c3e50;
      // color: #ecf0f1;
      // padding: 20px;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
    }
    .content {
      flex: 1;
      // color: #34495e;
    }
}
.affiliate-page {
    display: flex;
    min-height: 100vh;
    // margin-top: 97px;
    background-color: #f8f9fa;
    font-family: Arial, sans-serif;
  
    .sidebar {
      width: 405px;
      background-color: #26445c;
      color: #ecf0f1;
      // padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .header-icon {
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 23px;
        height: 100px;
        width: 405px;
        box-shadow: 0 16px 10px -5px #1d3549;
        // border-top: 1px solid #203b50;
      }

      img {
        width: 295px;
      }
  
      .affiliate-info {
        padding-top: 10px;
        text-align: center;
        
        .headline {
          font-size: 16px;
          font-family: 'Inter';
          // font-weight: bold;
        }

        h2 {
          font-size: 1.5em;
          margin-bottom: 10px;
        }
  
        .affiliate_name {
          font-size: 2em;
          font-family: 'Inter';
          // font-weight: bold;
          margin-top: 15px;
        }

        img {
          width: 80px;
          margin-top: -4px;
        }
  
        a {
          color: #f39c12;
          text-decoration: none;
          font-size: 1.5em;
          padding: 2px;
          font-family: 'Inter';
          // font-weight: bold;
          border-bottom-style: solid;
          border-bottom-width: 1px;
        }
      }
    }
  
    .content {
      flex: 1;
    //   padding: 20px;
    //   background-color: #34495e;
      color: #34495e;
      // position: relative;
      
      h2 {
        margin-bottom: 20px;
        font-size: 1.8em;
        text-align: left;
      }
  
      table {
        width: 100%;
        height: 100vh;
        // height: 94vh;
        // border-collapse: separate;
        border-collapse: collapse;
        border-spacing: 0;
        // margin-bottom: 20px;
        // margin-top: 94px;
        
        thead {
          background-color: #f2f2f2;
          box-shadow: 0px -4px 13px #2c3e50;
        }

        th, td {
          // border: 1px solid #bdc3c7;
          // padding: 10px;
          // text-align: left;
          // padding: 10px;
          text-align: center;
          position: relative;
          // border-bottom: 1px solid #ddd;
        }

        th {
          background-color: #f4f4f4;
          color: #34495e;
          // font-weight: bold;
          // font-size: large;
          font-family: 'Inter';
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-bottom: 2px solid #bdc3c7;
        }

        // tbody tr:hover {
        //   background-color: #f5f5f5;
        // }
        
        td {
          // background-color: #ffffff;
          color: #2b4960;
          // font-weight: bold;
          font-size: medium;
          font-family: 'Inter';
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-right-width: 2px;
          border-style: solid;
          border-color: #dbdbdb;
          // border-bottom: 1px solid #bdc3c7;
        } 

        tr {
          height: 45px;
        }

        tr:nth-child(even) {
          background-color: #e8ebec;
        }

        .bottom-tr {
          height: 100vh;
          background-color: #f8f9fa !important;
        }
  
        // tr:last-child td {
        //   border-bottom: 0;
        // }
      }
  
      .total-revenue {
        // position: absolute;
        bottom: 0px;
        justify-content: right;
        align-items: center;
        display: flex;
        height: 55px;
        box-shadow: 0px 2px 10px #2c3e50;
        padding-right: 30px;
        width: -webkit-fill-available;
        // text-align: right;
        // margin-top: 20px;
  
        h3 {
          color: #e67e22;
          font-size: 1.5em;
          font-weight: 550;
          margin-bottom: 0px;
        }
      }
    }
  }
  