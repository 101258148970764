.layout-one {
    .example-page {
        padding-bottom: 0;
        .main-content {
            padding-bottom: 30px;
            >div {
                width: 96%;
                max-width: 1024px;
                margin: 0 auto;
                text-align: left;

                .tab-buttons {
                    padding: 2.4vh 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    button {
                        background: none;
                        border: none;
                        font-weight: 600;
                        font-size: 24px;
                        width: 180px;
                        margin: 0 6px;
                        color: #26455d;
                    }
                    button.active {
                        color: #ef9f69;
                        border-bottom: 2px solid #ef9f69;
                    }
                }

                .slider-content{
                    margin-top: 3vh;
                    position: relative;                    
                    .example-item{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-direction: column;
                        img{
                            width: 312px;
                            height: 210px;
                            box-shadow:
                            6px 6px 5px #aaaaaa;
                            cursor: pointer;
                        }
                        label{
                            font-weight: 600;
                            color: #26455d;
                            margin-top: 2vh;
                            margin-bottom: 3vh;
                        }
                        button{
                            border: none;
                        }
                    }
                    img.slider-arrow.left-arrow{
                        height: 42px;
                        position: absolute;
                        left: -3vw;
                        cursor: pointer;
                        top: 10vh;
                    }                     
                    img.slider-arrow.right-arrow{
                        position: absolute;
                        height: 42px;
                        transform: rotate(180deg);
                        right: -3vw;
                        cursor: pointer;
                        top: 10vh;
                    }

                 
                }

                .description{
                    margin: 6vh 0;
                }

            }
        }


        .bg-reverse{
            background: transparent;
            color: white;
            h5{
                margin-top: 30px;
                font-weight: 600;
            }
        }

        .hear-about{
            padding: 10vh 0;
            >div{
                text-align: center;
                margin: 0 auto;
                max-width: 420px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                h3{
                    font-weight: 600;
                }

                .learn-more{
                    width: 72%;
                    margin-top: 3vh;
                }
            }
        }

    }
}