.layout-one {
    .pricing-comp {
        .title-comp {

            display: flex;
            background-color: #7a8491;
            justify-content: center;
            align-items: center;

            label {
                font-size: 28px;
                font-weight: 400;
                color: white;
                background: #323c49;
                padding: 3px 32px;
                margin: 10px;
            }

        }

        .price-blocks {
            background: white;

            .header-title {

                >h3 {
                    color: #fc7e29;
                    font-size: 36px;
                    padding-top: 12px;
                    text-transform: uppercase;
                    font-weight: 600;
                }

                >h4 {
                    color: #0d1e33;
                    font-weight: 600;
                    margin: 0;
                    font-size: 1.1rem;
                }

            }

            .price-content {
                max-width: 1024px;
                margin: 0px auto;
                padding: 16px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                .block-card {
                    width: 282px;
                    border: 1px solid lightgray;
                    background-color: #f4f7f9;
                    margin: 16px 0;

                    .block-header {
                        background-color: #0d1e33;
                        color: white;
                        font-size: 18px;
                        font-weight: 600;
                        padding: 6px;
                    }

                    .block-price {
                        p {
                            color: black;
                            text-align: center;
                            font-size: 21px;
                            font-weight: 600;
                            margin: 12px 0;
                        }

                        .deprecated {
                            font-size: 16px;
                            margin-bottom: 0;
                            text-decoration: line-through #ff8300;
                        }

                        .current {
                            margin-top: 0;
                        }
                    }

                    .block-tab {
                        margin: 0;
                        color: black;
                        padding: 16px 0;
                        border-top: 1px solid lightgray;
                        font-weight: 400;
                    }
                }
            }


        }

        .price-contact {
            max-width: 1024px;
            margin: 0 auto;
            color: white;
            text-align: left;

            h2 {
                padding: 12px 0;
            }

            >div {
                text-align: 'center';
                padding-bottom: 42px;
                padding-top: 16px;
                display: flex;
            }

        }

        .price-contact.terms-service {
            p {
                margin: 0;
                font-weight: 200;
                font-size: 13px;
            }

            h4 {
                text-align: center;
            }
        }

        .learn-more {
            display: block;
            font-weight: bold;
            margin: 0px auto;
            border-radius: 24px;
            background: #ef9f69;
            color: white;
            border: 1px solid;
            font-size: 14px;
            padding: 6px 24px;
            cursor: pointer;
            margin-bottom: 42px;
            border: none;
        }


    }


    /* Container styles */
    .dots-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        margin-left: 6px;
        margin-top: 6px;
        /* Space between dots */
    }

    /* Common styles for dots */
    .dots-loader div {
        width: 4px;
        height: 4px;
        background-color: white;
        border-radius: 50%;
        animation: bounce 1s infinite ease-in-out;
    }

    /* Animation delay for each dot */
    .dots-loader div:nth-child(1) {
        animation-delay: 0s;
    }

    .dots-loader div:nth-child(2) {
        animation-delay: 0.2s;
    }

    .dots-loader div:nth-child(3) {
        animation-delay: 0.4s;
    }
}