input,
button {
  outline: none;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #26455d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #264556;
}

header {
  width: 100%;
}

p {
  white-space: normal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

textarea:focus,
input:focus,
button:focus {
  outline: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter/Inter-Regular.ttf') format('opentype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter/Inter-Medium.ttf') format('opentype');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter/Inter-RegularItalic.ttf') format('opentype');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter/Inter-Bold.ttf') format('opentype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter/Inter-BoldItalic.ttf') format('opentype');
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

.App {
  text-align: center;
  background-color: #26455d;
  color: #26455d;
  font-family: 'Inter';
  min-height: 100vh;

  .mobile-contents {
    display: none;
  }
}

.App-header {
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;

  a {
    img {
      height: 70px;
      margin: 2vh;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-around;
    width: 55%;

    li>a {
      color: white;
      text-decoration: none;
      font-size: 1.2vw;
    }

    li>a {
      padding: 3px;
    }

    li>a.active {
      border-bottom: 1px solid gold;
    }


    li:last-child {
      display: none;
    }

  }
}

.header-title {
  color: white;
  font-weight: 200;

  h2 {
    font-weight: 200;
    font-size: 3.2vw;


    b {
      font-weight: bold;
    }
  }

  h3 {
    font-weight: 200;
    font-size: 1.5vw;

  }

}


.schedule-call {
  background: #fc7e29;
  border: none;
  border-radius: 30px;
  font-size: 21px;
  color: white;
  font-weight: 800;
  padding: 12px 21px;
  margin-top: 1vh;
}

.schedule-call:hover{
  color: white;
  text-decoration: none;
}

.layout-one {
  width: 100%;

  #DSB-video {
    offset-anchor: top;
  }

  .header-firstpage {
    background-image: url('./assets/header-img/home-bg.webp');
  }

  .header-ecommerce {
    background-image: url('./assets/header-img/ecommerce-bg.webp');
  }

  .header-example {
    background-image: url('./assets/header-img/pricing-bg.webp');
  }

  .header-contactus {
    background-image: url('./assets/header-img/contactus-bg.webp');
  }

  .header-part {
    padding: 16vh 3%;
    background-size: cover;
  }

  .header-part.terms-service {
    padding-bottom: 9vh;
  }

  .header-part.demo-content {
    padding: 0;
    padding-top: 10vh;

    iframe {
      width: 100%;
      height: 72vh;
      border: none;
      transform: translateY(4px);
    }
  }

  .shopify {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    h3 {
      color: white;
      margin-left: 6%;
      font-size: smaller;
      font-weight: bold;
      margin-bottom: 0;
    }

    img {
      width: 9%;
      margin: 1%;
    }

  }

  .main-content {
    background: white;
    display: flex;

    .config-content {
      text-align: left;
      color: #26455d;
      margin: auto;

      .have-comment {
        text-align: center;
        width: 100%;
      }

      .threed-config {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;

        >div {
          padding: 6px;
          border-bottom: 1px solid #dbd8d8;
          a {
            color: inherit;
            span {
              font-size: 25px;
              margin-left: 2vw;

            }
          }

        }

        img {
          margin: auto;
          width: 75%;
        }
      }

      .shed-ad {
        display: flex;
        justify-content: space-around;
        margin: 4vh 0;

        .half-side {
          line-height: 120%;
          width: 45%;

          >img {
            width: 100%;
            box-shadow:2px 2px 6px #aaaaaa;
          }

          .content-block {
            margin: 8vh 0;

            p {
              word-break: normal;
            }

            .side-title {
              line-height: 120%;
              font-size: 28px;
            }

          }
        }
      }

      .pricing-form {
        .b-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          textarea {
            padding: 5px;
          }

          .b-col {
            width: 48.5%;

            input {
              width: 100%;
              padding: 5px;
            }

            ::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              opacity: 0.5;
              /* Firefox */
            }

            :-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              opacity: 0.5;
            }

            ::-ms-input-placeholder {
              /* Microsoft Edge */
              opacity: 0.5;
            }
          }

          .submit-message {
            background: #ef9f69;
            color: white;
            border: none;
            padding: 7px 8%;
            font-size: 14px;
            border-radius: 20px;
            cursor: pointer;
          }
        }
      }

      .contact-items {
        position: relative;
        margin-top: -21px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        .contact-item {
          background: white;
          width: 30%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          border: 1px solid #eceef3;
          padding: 10px 0px;
          height: 100%;

          img {
            width: 42px;
          }

          h3 {
            margin: 7px 0px;
            font-size: 24px;
            font-weight: 400;
          }

          .contact-info {
            width: 100%;

            p {
              font-size: large;
              text-align: center;
              margin: 1.5%;
            }
          }
        }
      }

      .side-title {
        line-height: 120%;
        font-size: 28px;
      }
      .side-title.text-center{
        margin: 2.4vh 0;
      }

      .img-box{
        display: flex;
        justify-content: space-between;
        padding: 3vh 3vw;
        align-items: flex-start;
        img{
          box-shadow: 2px 2px 6px #aaaaaa;
          border-radius: 3px;
        }

        img:nth-child(1){
          width: 38%;
        }        
        img:nth-child(2){
          width: 25.3%;
        }        
        img:nth-child(3){
          width: 34%;
        }

      }

      .img-box.marketing-insight{

        img:nth-child(1){
          width: 62%;
        }        
        img:nth-child(2){
          width: 37%;
          box-shadow: none;
        }        
       

      }


    }

  }

  .blue-content {
    background: #314157;
  }

  .online-sales {

    background-color: #26455d;
    color: white;
    flex-direction: column;
    padding-bottom: 21px;

    .sales-title {
      font-size: 24px;
      margin-top: 42px;
    }

    .brandings {
      display: flex;
      justify-content: center;
      align-items: center;

      .branding-item {
        padding-left: 17px;
        font-size: smaller;
        font-weight: bold;
        line-height: 150%;
        text-align: left;
        width: 28%;
        border: 1px solid white;
        text-align: left;
        padding-right: 7px;
        margin: 12px;

        .branding-header {
          display: flex;
          align-items: center;
          font-size: large;
          margin-bottom: 2px;

          img {
            height: 36px;
          }

        }
      }

    }

  }

  .online-config {
    font-size: small;

    padding-top: 21px;

    .config-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .half-content {

        width: 72%;
        padding: 2%;
        padding-bottom: 0;

        .mark-content {
          padding-left: 15px;
          padding-right: 15px;
          display: flex;
          flex-direction: column;
          justify-content: top;
          align-items: center;
        }
      }
    }
  }

  .main-content {

    font-size: 1rem;
    padding: 0 3vw;

    .somethings {
      display: flex;

      .something-item {
        width: 33%;
        border: 1px solid #eee;
        padding: 12px;

        h5 {
          margin: 5px;
        }

        p {
          margin-top: 0;
        }
      }
    }
  }

  .getting-config {
    background-color: #4b7596;
    color: white;

    .config-content {
      width: 100%;
      color: white;
      padding: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 21px;

      .learn-more {
        margin-left: 1%;
        font-size: 14px;
      }
    }
  }

  .learn-more-p {
    margin-top: 32px;
    text-align: right;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .learn-more {
      border-radius: 20px;
    }
  }

  .learn-more-p.btn-get-started {
    // position: absolute;
    // margin-top: -54px;
    font-size: 18px;
  }

  .learn-more {
    font-weight: bold;
    background: #ef9f69;
    color: white;
    padding: 6px 24px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    // min-width: 120px;
  }

  .learn-more-btn {
    max-width: 126px;
  }
}

.gap-head {
  height: 80px;
  background-color: #0d1e33;
}

.search-bar {
  .navbar-right {
    display: flex;
    flex-direction: row;
    padding: 1vh 3vw;
    justify-content: flex-start;
    align-items: center;

    li.hidden-xs {
      background: white;
      margin: 0 6px;
      border-radius: 50%;

      a {
        font-size: 18px;
        color: #26455d;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    li.login-link {
      margin: 0 6px;
      display: flex;

      div {
        display: flex;

        button {
          background: #ef9f69;
          color: white;
          font-weight: 400;
          padding: 9px 12px;
          border: none;
          font-size: 13px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        button.arrow {
          background: #ff9f69;
          font-size: 16px;
        }

      }

      .cancel-subscription {
        margin-right: 6px;

        button {
          background: none;

        }
      }

      button:hover {
        opacity: 0.8;
      }
    }
  }



  input {
    padding-bottom: 0px;
    margin: 9px;
    border-radius: 15px;
    padding: 5px;
    padding-left: 10px;
    border: none;
    font-size: 13px;
    width: 205px;

  }

  // button {
  //   font-weight: bold;
  //   margin: 9px;
  //   border-radius: 15px;
  //   background: #ef9f69;
  //   color: white;
  //   border: 1px solid;
  //   font-size: 12px;
  //   padding: 7px 16px;
  //   cursor: pointer;
  // }

  display: flex;
  justify-content: flex-end;

}


.App-link {
  color: #61dafb;
}

.first-page {

  // padding-bottom: 6%;
  .video-window {
    margin: 0 auto;
  }
}


.Integrate-platform {
  color: white;

  p {
    font-size: 24px;
    margin: 10px 0;
  }

  .integrates {
    display: flex;
    justify-content: center;

    .integrate-item {
      width: 14%;
      margin: 1%;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.2%;
      border-radius: 7px;

      img {
        width: 100%;
      }
    }
  }
}

.user-auth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input {
    width: 72%;
    margin: 2%;
  }
}

.main-content {
  .inner-content {
    width: 82%;
    margin: auto;

    .content-item {
      width: 46%;
      margin: 1%;
      display: inline-block;
      background-color: white;
      padding: 1%;
      box-shadow:2px 2px 6px #aaaaaa;

      .layout {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;

        img {
          width: 14%;
          margin-top: -36px;
        }

        >div {
          width: 82%;
        }

        h4 {
          margin-bottom: 0px;
        }

        p {
          margin-top: 5px;
        }
      }
    }
  }
}

.layout-one {

  .single-platform {
    flex-direction: row;
    padding: 4%;

    img {
      width: 35%;
    }

    .single-platform-content {
      margin-left: 8%;
      width: 42%;
      text-align: left;
    }

  }
}


/* Table 1 Style */

table.table1 {
  font-family: "Trebuchet MS", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.4em;
  font-style: normal;
  border-collapse: separate;
  margin: auto;
  padding: 2%;
  border-spacing: 0px;
  width: 80%;
}

.table1 thead th:nth-child(2) {
  background: #79abc1;
}

.table1 thead th:nth-child(3) {
  background: #3e5f81;
}

.table1 thead th:nth-child(4) {
  background: #242f54;
}

.table1 thead th:nth-child(5) {
  background: #222a41;
}

.table1 thead th {
  padding: 15px;
  color: #fff;
  width: 20%;
  text-align: center;
  border: 1px solid white;
}

.table1 thead tr:first-child th {

  font-size: 12px;
  line-height: 0;

}

.table1 thead th:empty {
  background: transparent;
  border: none;
}

.table1 tbody th,
td {
  color: grey;
  padding: 0px 10px;
  text-align: left;
  line-height: 32px;
  font-weight: 200;
}





.table1 tfoot td {
  color: #9CD009;
  font-size: 32px;
  text-align: center;
  padding: 10px 0px;
}

.table1 tfoot th button {
  border: 1px solid white;
  padding: 3px 15px;
  border-radius: 14px;
  background: no-repeat;
  color: white;
  cursor: pointer;
}

.table1 tfoot th {
  color: white;
  border: 1px solid white;

}


.table1 tfoot th:nth-child(2) {
  background: #79abc1;
}

.table1 tfoot th:nth-child(3) {
  background: #3e5f81;
}

.table1 tfoot th:nth-child(4) {
  background: #242f54;
}

.table1 tfoot th:nth-child(5) {
  background: #222a41;
}


.table1 tbody td,
th {
  padding: 10px;
  text-align: center;
  background-color: white;
  border: 1px solid gainsboro;

}

.table1 tbody {
  border-spacing: 1px;
}



.table-context {
  font-size: 17px;
}

.main-content {
  .config-action {
    p {
      text-align: center;
      font-weight: 100;
    }

    p:nth-child(1) {
      font-size: 2vw;
    }

    p:nth-child(2) {
      font-size: 1vw;
    }

    .learn-more {
      font-weight: 600;
    }
  }
}

.display-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-div {
  color: white;
  padding: 3vh 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0;

    span {
      padding-left: 4vw;
      cursor: pointer;
    }

    span:hover {
      text-decoration: underline;
    }
  }



  .navbar-right {
    display: flex;
    flex-direction: row;
    padding: 1vh 3vw;
    justify-content: flex-start;
    align-items: center;

    li.hidden-xs {
      background: white;
      margin: 0 6px;
      border-radius: 50%;

      a {
        font-size: 18px;
        color: #26455d;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

      }
    }


  }



}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 992px) {

  .App-header {
    ul {
      width: 80%;

      li>a {
        font-size: 1.7vw;
        font-weight: bold;
      }
    }

    a {
      img {
        height: 48px;
      }
    }
  }

  .header-title {
    h2 {
      font-size: 25px;
      margin-top: 36px;
    }

    h3 {
      font-size: 17px;
    }
  }

  .layout-one {

    .header-part {
      padding-top: 6vh;
      padding-bottom: 6vh;
    }


    .shopify {
      justify-content: center;

      h3 {
        display: none;
      }

      img {
        width: 20%;
      }
    }

    .main-content {
      .config-content .threed-config span {
        font-size: 3.8vw;
      }

      .config-content .shed-ad {
        flex-direction: column;

        .half-side {
          width: 100%;
        }
      }

      .somethings {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .something-item {
          width: 86%;
          margin: 0.5%;
        }
      }

    }

    .online-sales .brandings {
      flex-direction: column;

      .branding-item {
        width: 82%;
      }
    }

    .online-config .config-content {
      flex-direction: column;

      .half-content {
        width: 92%;

        .mark-content {
          p {
            margin-top: 7px;
          }
        }
      }
    }

    .main-content .inner-content .content-item {
      display: block;
      width: 100%;
      margin: 15px;
    }

    .contact-us {

      .config-sell {
        flex-direction: column;
      }

      .config-sell .config-sell-left {
        width: 90%;
        margin: auto;
      }

      .config-sell .config-sell-right {
        width: 90%;
        margin: auto;
      }

      .config-sell .config-sell-left .title {
        font-size: 4vw;
      }

      .config-sell .config-sell-right .show-me-card {
        margin: auto;
        width: 100%;
        min-width: 272px;
      }

      .config-sell-left {
        ul li>div {
          font-size: 24px;
          width: 48px;
          height: 48px;
        }

        ul li>p {
          width: calc(100% - 56px);
          font-size: 18px;
        }
      }


    }

    .config-sell {
      flex-direction: column;
    }

    .contact-us.demo-page .config-sell {
      flex-direction: column-reverse;
    }

    .config-sell .config-sell-left {
      width: 90%;
      margin: auto;
    }

    .config-sell .config-sell-right {
      width: 90%;
      margin: auto;
    }

    .config-sell .config-sell-left .title {
      font-size: 4vw;
    }

    .config-sell .config-sell-right .show-me-card {
      margin: auto;
      width: 60%;
      min-width: 272px;
    }


    .pricing-comp {

      .price-blocks {
        padding: 0 3%;

        .price-content {
          justify-content: center;

          .block-card {
            margin: 1vw;
          }
        }
      }

      .price-contact {
        padding: 0 3%;
      }
    }

    .example-page {
      .main-content>div .slider-content img.slider-arrow.left-arrow {
        height: 24px;
      }

      .main-content>div .slider-content img.slider-arrow.right-arrow {
        height: 24px;
      }

    }
  }

  .layout-one .getting-config .config-content {
    flex-direction: column;
    font-size: 4vw;

    .learn-more {
      margin-top: 21px;
    }
  }



  .layout-one .single-platform {
    flex-direction: column;
  }

  .layout-one .single-platform img {
    width: 86%;
    margin: auto;
  }

  .layout-one .single-platform .single-platform-content {
    margin: auto;
    width: 86%;
  }

  .footer-div {
    .navbar-right li.hidden-xs a {
      font-size: 1.8vw;
    }

    p {
      font-size: 1.6vw;
    }
  }

  .signin-comp .title-comp label {
    font-size: 21px;
  }

  .payment-comp .title-comp label {
    font-size: 21px;
  }

  .payment-comp .description {
    width: 92%;
  }

  .payment-comp .modal-content {
    width: 92%;

    .subscribe-content .subscribe {
      h4 {
        font-size: 18px;
      }

      h5 {
        font-size: 14px;
      }

    }

    .control-coupon {
      padding-right: 0;
    }
  }

  .signin-comp .price-contact {
    .account-info {
      width: 92%;

      div.button-div button {
        padding: 3px 6vw;
      }
    }

    >h3 {
      width: 92%;
    }
  }

}


@media screen and (max-width: 720px) {

  .dropleft .dropdown-toggle::before {
    display: none;
  }

  .App-header {
    ul {
      width: 80%;
      li>a {
        font-size: 1.7vw;
        font-weight: bold;
      }
      li {
        display: none;
      }
      li:last-child {
        display: block;

        .mobile-menu {
          padding: 0;
          background: none;
          color: white;
          border: none;
          font-size: 24px;
          margin-right: 12px;
        }
      }
    }
  }


  .App-header ul {
    justify-content: flex-end;
    padding-right: 3%;
    font-weight: bold;
  }

  // .first-page {
  //   // padding-bottom: 10%;
  // }

  .layout-one .main-content .config-content .contact-items {
    flex-direction: column;

    .contact-item {
      width: 100%;
      margin-top: 10px;
    }
  }

  .layout-one .main-content .config-content .pricing-form .b-row {
    textarea {
      padding: 0;
    }
  }

  .layout-one .main-content .config-content .pricing-form .b-row .b-col input {
    padding: 0;
  }


  .table1 tr th:first-child {
    display: none;
  }

  .table1 thead th {
    font-size: 2vw;
    line-height: 12px;
  }

  .btn-group>button {
    background: no-repeat;
    border: 1px solid;
    color: white;
    border-radius: 6px;
    font-size: 18px;
  }

  .table1 tfoot th button {
    font-size: 2vw;
  }

  .table-context {
    font-size: 15px;
  }

  .main-content .config-action p:nth-child(1) {
    font-size: 4vw;
  }

  .main-content .config-action p:nth-child(2) {
    font-size: 3vw;
  }

  .table1 thead th {
    padding: 7px;
  }

  .table1 thead tr:first-child th {
    font-size: 2vw;

  }


  .footer-div {
    flex-flow: column-reverse;

    .navbar-right li.hidden-xs a {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }
  }


  .App {


    .mobile-contents {
      position: fixed;
      top: 60px;
      background: white;
      width: 100%;
      height: calc(100% - 60px);
      touch-action: none;
      padding: 2.4vw;
      display: block;

      .menu-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: black;

        img {
          height: 48px;
        }

        h3 {
          margin: 0;
          font-size: 6vw;
          font-weight: 600;
        }

        button {
          border: none;
          padding: 0;
          background: none;
          font-size: 6vw;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      ul {
        list-style: none;
        text-align: left;
        margin-top: 2vh;
        padding: 0;

        li {
          font-weight: 600;
          margin: 0 2.4vw;
          padding: 9px 0;
          border-bottom: 1px solid lightgray;
          cursor: pointer;

          a {
            color: #26455d;
          }
        }
      }
    }

  }

  .App.mobile-menu-open {
    .layout-one {
      display: none;
    }
  }

  .layout-one .main-content .config-content .threed-config>div a span {
    font-size: 4.8vw;
  }

  .img-box{
    flex-wrap: wrap;
    >img{
      width: 100% !important;
      margin-top: 12px;
    }
  }

  

  .layout-one .example-page .main-content > div .tab-buttons button{
    height: 60px;
    width: 76px;
    font-size: 16px;
    margin: 0 3px;
  }


}

@media screen and (max-width: 450px) {
  
  .layout-one {
    width: 100%;
    .header-part.demo-content {
      padding: 0;
      padding-top: 10vh;

      iframe {
        width: 100%;
        height: 66vh;
        border: none;
        transform: translateY(4px);
      }
    }
  }
}